define(() => {
    var eGiftEmail = () => {

        const component = {};
        
        const _init = (element) => {
            component.element = element;
            component.elements();
            component.bindEvents();
        }

        const _elements = () => {
            component.formElements = {
                recipientEmail: component.element.querySelector("#eGiftEmail_recipientEmail"),
                message: component.element.querySelector("#eGiftEmail_recipientMessage"),
                form: component.element.querySelector("#eGiftEmail_form"),
                submit: component.element.querySelector("[data-egiftemail-submit]"),
                error: {
                    container: component.element.querySelector("[data-egiftemail-error]"),
                    email: component.element.querySelector("#eGiftEmail_error-one"),
                    message: component.element.querySelector("#eGiftEmail_error-two")
                },
                success: component.element.querySelector("[data-egiftemail-success]")
            }
        }

        const _bindEvents = () => {
            component.formElements.form.addEventListener("submit", component.validateForm);
        }

        const _validateForm = (event) => {

            event.preventDefault();

            // Provide quicker access to form vals + will be used as json body.
            const formData = {
                recipientEmail: component.formElements.recipientEmail.value,
                message: component.formElements.message.value
            };

            // Boolean identifies if form has error.
            var hasErr = false;
            
            if(!component.isEmail(formData.recipientEmail) || !component.notEmpty(formData.recipientEmail)){
                component.formElements.error.email.className = "eGiftEmail_errorMessage-text";
                hasErr = true;
            } else {
                component.formElements.error.email.className = "eGiftEmail_errorMessage-text eGiftEmail_hide";
            }

            if(!component.notEmpty(formData.message)){
                component.formElements.error.message.className = "eGiftEmail_errorMessage-text";
                hasErr = true;
            } else {
                component.formElements.error.message.className = "eGiftEmail_errorMessage-text eGift_hide";
            }

            // Return false on form error and display error container. Otherwise, continue.
            if(hasErr) {
                event.preventDefault();
                component.formElements.error.container.className =  "eGiftEmail_error";
                return false;
            } else {
                component.formElements.error.container.className = "eGiftEmail_error eGiftEmail_hide";
                component.postData(formData);
            }
        }

        const _postData = (formData) => {

            let GOOGLE_RECAPTCHA_RESPONSE = document.querySelector('#g-recaptcha-response');
            GOOGLE_RECAPTCHA_RESPONSE = GOOGLE_RECAPTCHA_RESPONSE ? GOOGLE_RECAPTCHA_RESPONSE.value.toString() : '0';

            fetch(`/giftSentEmail.account?g-recaptcha-response=${GOOGLE_RECAPTCHA_RESPONSE}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            })
            .then(res=>{

                if(res.ok){
                    // On OK response, check if redirect due to unauthed user.
                    if(res.url.includes("returnTo=")){
                        // override the redirect which would submit formData rather than json.
                        let overwriteUrl = res.url.replace("giftSentEmail.account", window.location.pathname.replace("/", ""));
                        window.location.replace(overwriteUrl);
                    } else {
                        component.formElements.success.classList.remove("eGiftEmail_hide");
                        component.formElements.submit.classList.add("eGiftEmail_hide");
                    }
                } else {
                    component.formElements.success.classList.add("eGiftEmail_hide");
                    component.formElements.submit.classList.remove("eGiftEmail_hide");
                }
                
            })
            .catch(err=>console.log(err));

        }

        const _notEmpty = function(object) {
            if (typeof (object) === 'string') {
                object = object.trim();
            }
            return (object !== '' && object !== null && object !== undefined);
        };
      
        const _isEmail = function(email) {
            var regex = /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+([A-Za-z]{2,6})$/;
            if (component.notEmpty(email)) {
                return regex.test(email);
            } else {
                return false;
            }
        };


        component.init = _init;
        component.elements = _elements;
        component.bindEvents = _bindEvents;
        component.validateForm = _validateForm;
        component.postData = _postData;
        component.isEmail = _isEmail;
        component.notEmpty = _notEmpty;

        
        return component;
    };

    return eGiftEmail;
});